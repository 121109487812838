import "../css/medspa-network-v3.webflow.css";
import "../css/normalize.css";
import "../css/webflow.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import {
  Primary_button_dark,
  Primary_button_light,
  Medium_button_secondary_light,
} from "../components/buttons";

export default function GLPInformationVIO() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    signatureDate: new Date().toLocaleDateString(),
  });

  async function submitForm(e) {
    e.preventDefault();
    document.getElementById("submit").value = "Submitting...";
    document.getElementById("submit").disabled = true;
    try {
      const response = await axios.post(
        "/api/v2/emails/semaglutide-landing-page",
        formData
      );

      if (response.data.status === "success") {
        navigate(`/glp-signup?${search}&success=true`);
        document.getElementById("submit").value = "Success";
      } else {
        setErrorMessage("Something went wrong, please try again");
        document.getElementById("submit").value = "Submit";
        document.getElementById("submit").disabled = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {/* <Navbar type="dark" /> */}
      <section
        className="dark-background-section image"
        style={{
          backgroundImage: `linear-gradient( rgba(13, 14, 70, 0.78), rgba(11, 9, 61, 0.69) ), url(${require("../assets/images/semaglutide-background.png")})`,
          height: "auto",
        }}
      >
        <div
          className="w-layout-blockcontainer container gap-m "
          style={{ overflow: "visible" }}
        >
          <img src="content/logos/vio-medspa-light.svg" />
          <div className="header-l center">
            Accelerate Your Practice with V/O Medspa and MedSpa Network's
            Manufactured GLP-1 Program
          </div>
          <div className="header-xs light" style={{ textAlign: "center" }}>
            V/O and MedSpa Network have partnered to provide medical practices
            and professionals with a complete GLP program, offering streamlined
            access to GLP-1 medications, expert training, and dedicated support.
            Our program is designed to help seamlessly integrate these advanced
            treatments into your practice, empowering providers to deliver
            effective, cutting-edge care to their patients.
            <br />
          </div>
          <div>
            <a
              href="/glp-signup"
              className="button-dense-primary-light "
              style={{ textDecoration: "none" }}
            >
              Get Started Today
            </a>
          </div>
        </div>
      </section>
      <section id="How" className="light-background-section white">
        <div className="w-layout-blockcontainer container left-justify">
          <div className="paragraph-section">
            <h5 className="paragraph-section-heading">Is it for me?</h5>
            <h1 className="header-xl">Why Choose Our GLP-1 Program?</h1>
          </div>
          <div
            id="w-node-_8ff770a1-9997-4c80-5c92-623e14884223-14884223"
            className="w-layout-layout three-stack wf-layout-layout"
            style={{ paddingTop: 64 }}
          >
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e14884224-14884223"
              className="w-layout-cell cell-8"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={"/content/staff/resized/squadEdit3.webp"}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">
                  Join a Community of Experts
                </h2>
                <p className="paragraph-card-details">
                  Learn from board-certified plastic surgeons and renowned
                  medical professionals, gaining both knowledge and a supportive
                  network of peers and mentors.
                </p>
              </div>
            </div>
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e1488422b-14884223"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/introduction_to_neuromodulators.jpg")}
                  alt=""
                  className="paragraph-card-image"
                />
                <h2 className="paragraph-card-heading">
                  Stay Ahead with Cutting-Edge Techniques
                </h2>
                <p className="paragraph-card-details">
                  Master the latest non-surgical aesthetic procedures alongside
                  a community of professionals dedicated to advanced techniques
                  and patient safety.
                </p>
              </div>
            </div>
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e14884232-14884223"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/Rectangle-11.png")}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">
                  Connect with Partners &amp; Industry Leaders
                </h2>
                <p className="paragraph-card-details">
                  Leverage our partnerships with top pharmaceutical companies
                  and industry innovators to access the best products, devices,
                  and professional networks for collaboration and growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blue-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs title-underline light">
            Why Choose V/O's GLP Program?
          </div>
          <div className="header-xl top-pad-m">
            Exclusive Benefits with V/O and MedSpa Network's GLP-1 Partner
            Program
          </div>
          <div className="header-xs light">
            The GLP-1 Partner Program is designed to provide unmatched
            convenience, affordability, and efficiency for your practice. With a
            focus on seamless integration and patient satisfaction, our program
            offers:
          </div>
          <div
            id="w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1"
            className="w-layout-layout full-width-stack pad-top-xl wf-layout-layout"
          >
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088caf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/direct-to-patient.png")} />
              <div>
                <div className="header-m">Direct-to-Patient Access</div>
                <p className="body-s light">
                  Ensure fast and convenient delivery of manufactured GLP-1
                  product directly to your patients.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cb7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/low-cost.png")} />
              <div>
                <div className="header-m">Low Cost to Provider</div>
                <p className="body-s light">
                  Exclusive competitive pricing, includes shipping with bulk
                  orders to maximize affordability for your practice.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cbf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/no-cold-shipping.png")} />

              <div>
                <div className="header-m">No Cold Shipping Required</div>
                <p className="body-s light">
                  Shipping manufactured GLP-1 eliminates the need for
                  specialized cold shipping.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cc7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/inventory.png")} />

              <div>
                <div className="header-m">Guaranteed Inventory</div>
                <p className="body-s light">
                  Never worry about stock shortages—our guaranteed inventory and
                  management system prevents patient and practice delays.
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cc7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/buy-and-bill.png")} />

              <div>
                <div className="header-m">Buy and Bill Model</div>
                <p className="body-s light">
                  Streamline billing with provider prescription through
                  distribution partner, The Pharmacy Hub.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="semaglutide" className="lightgrey-background-section">
        <div
          className="w-layout-blockcontainer container gap-l left-justify"
          style={{ rowGap: 32 }}
        >
          <div className="full-width space-between">
            <h1 className="header-m">Exclusive access to High-End Products</h1>
            <a href="#" className="link-block icon-button w-inline-block">
              <div className="button-m light">See All</div>
              <img
                src={require("../assets/icons/next-1.png")}
                loading="lazy"
                alt=""
                className="tiny-icon"
              />
            </a>
          </div>
          <div className="slider-container">
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">1mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={
                    "https://www.medspanetwork.com/content/product-images/glp/semaglutide1mg.png"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">Total mg: 1mg</p>
                <p className="body-s">Strength: 1mg/ml</p>
                <p className="body-s">Size: 1ml</p>
                <p className="body-s">Units: 100</p>
                <p className="body-s">Price: $84.00</p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-signup"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">2.5mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={
                    "https://www.medspanetwork.com/content/product-images/glp/Semaglutide2-5mg.png"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">Total mg: 2.5mg</p>
                <p className="body-s">Strength: 2.5mg/ml</p>
                <p className="body-s">Size: 1ml</p>
                <p className="body-s">Units: 100</p>
                <p className="body-s">Price: $101.00</p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-signup"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">5mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={
                    "https://www.medspanetwork.com/content/product-images/glp/Semaglutide5mg.png"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">Total mg: 5mg</p>
                <p className="body-s">Strength: 2.5mg/ml</p>
                <p className="body-s">Size: 2ml</p>
                <p className="body-s">Units: 200</p>
                <p className="body-s">Price: $147.00</p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-signup"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">12.5mg Semaglutide</div>
              <div className="div-block-17">
                <img
                  src={
                    "https://www.medspanetwork.com/content/product-images/glp/Semaglutide12-5mg.png"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">Total mg: 12.5mg</p>
                <p className="body-s">Strength: 5mg/ml</p>
                <p className="body-s">Size: 2.5ml</p>
                <p className="body-s">Units: 250</p>
                <p className="body-s">Price: $215.00</p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-signup"
                />
              </div>
            </div>
          </div>
          <div className="slider-container">
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">10mg Tirzepatide</div>
              <div className="div-block-17">
                <img
                  src={
                    "https://www.medspanetwork.com/content/product-images/glp/tirzepatide-10mg-ml.JPG"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">Total mg: 10mg</p>
                <p className="body-s">Strength: 10mg/ml</p>
                <p className="body-s">Size: 1ml</p>
                <p className="body-s">Price: $153.00</p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-signup"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">30mg Tirzepatide</div>
              <div className="div-block-17">
                <img
                  src={
                    "https://www.medspanetwork.com/content/product-images/glp/tirzepatide-30mg-3ml.JPG"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">Total mg: 30mg</p>
                <p className="body-s">Strength: 10mg/ml</p>
                <p className="body-s">Size: 3ml</p>
                <p className="body-s">Price: $251.00</p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-signup"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">60mg Tirzepatide</div>
              <div className="div-block-17">
                <img
                  src={
                    "https://www.medspanetwork.com/content/product-images/glp/tirzepatide-60mg-3ml.JPG"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">Total mg: 60mg</p>
                <p className="body-s">Strength: 20mg/ml</p>
                <p className="body-s">Size: 3ml</p>
                <p className="body-s">Price: $360.00</p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-signup"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-l full-width center">FAQ&#x27;S</div>
          <div
            className="faqs"
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 32,
              paddingTop: 32,
            }}
          >
            <div className="faq-row">
              <div className="header-s">
                What makes our GLP-1 offering unique?
              </div>
              <p className="body-s">
                Our GLP-1 therapies are sourced directly from FDA-approved
                manufacturers, ensuring the highest quality and safety. Unlike
                compounded alternatives, our semaglutide products come with an
                eight-month Beyond Use Date (BUD). Additionally, you gain direct
                access to Dr. Alan Durkin for expert guidance and advice on
                integrating GLP-1 therapies into your practice.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Who can purchase GLP-1's from us?</div>
              <p className="body-s">
                Licensed healthcare providers, such as physicians, nurse
                practitioners, and other qualified medical professionals, can
                purchase GLP-1 products from our program.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">
                How does the shipping process work?
              </div>
              <p className="body-s">
                Our semaglutide products are shipped with no cold shipping
                requirements, using 2nd day UPS shipping. For added safety in
                warmer months, cold shipping may be used at an additional cost
                of $10. Once an order is processed by 4:00 PM EST, it will
                arrive within two business days (Monday through Wednesday).
                Orders placed on Thursdays and Fridays will ship the following
                Monday.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">Can I order in bulk?</div>
              <p className="body-s">
                Yes, bulk orders are available for clinics. A Bulk Order
                consists of 25 vials, and products are shipped to various states
                including AZ, CO, FL, GA, IL, MA, NC, TX, and more.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">What is the Buy-and-Bill program?</div>
              <p className="body-s">
                Our semaglutide program operates on a Buy-and-Bill basis.
                Providers can either prescribe individual doses for patients or
                place bulk orders for clinic use. Once a prescription is filled,
                the practice or medical spa will be billed for the order.
                <br />
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">How do I place an order?</div>
              <p className="body-s">
                Orders can be placed through our distribution partner, The
                Pharmacy Hub. The Pharmacy Hub handles all product logistics,
                billing, and shipping from their state-of-the-art facilities.
                After registering with The Pharmacy Hub, providers can order
                products online through their DispensePro site.
                <br />
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">
                How do I set up an account with The Pharmacy Hub?
              </div>
              <p className="body-s">
                Providers, clinics, and medical spas must complete an MedSpa
                Network onboarding form, providing relevant information such as
                DEA number, NPI number, and state licensure. This form is
                required before prescribing and ordering products. Account
                verification may take up to 3 business days. Once processed,
                you'll receive a welcome email with account details and an
                instructional video for ordering through DispensePro.
                <br />
              </p>
            </div>
            <div className="faq-row">
              <div className="header-s">
                What is the billing process for prescriptions?
              </div>
              <p className="body-s">
                For the Buy-and-Bill program, your practice will be billed for
                each prescription. The Pharmacy Hub processes payments using the
                credit card on file once an order is received. Upon payment
                confirmation, the prescription is immediately fulfilled and
                shipped.
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
}
